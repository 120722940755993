import type { FC } from 'react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import { useTheme } from '@emotion/react'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Footer from '@segment/templates/Footer'
import Navigation from '@segment/templates/Navigation'

import Layout from '@segment/next-shared/layout'

import Hero from './components/Hero'
import TestimonialsSlider from './components/TestimonialsSlider'
import TheGrowthReport from './components/TheGrowthReport'
import GettingStarted from './components/GettingStarted'
import DataArchitecture from './components/DataArchitecture'
import UseCase from './components/UseCase'
import Integrations from './components/Integrations'
import ActivateWarehouseData from './components/ActivateWarehouseData'
import Interactions from './components/Interactions'

import ogImageJpeg from './assets/OgHome.png'

const LogoCarousel = dynamic(() => import(`@segment/templates/LogoCarousel`))

const announcementsCopy = {
  announcement: {
    description: `Join us on August 28th to Uncover AI’s Role in Closing the Personalization Gap with MoneyLion`,
    ctaTitle: `Register now`,
    ctaLink: `https://webinars.segment.com/segment/cdp-live-closing-the-personalization-gap?utm_source=homepage-banner&utm_medium=referral&utm_campaign=all_ic_2024-08-28-wbnr-cdp-live_`,
  },
  mobileAnnouncement: {
    description: `Uncover AI’s Role in Closing the Personalization Gap`,
    ctaTitle: `Register now`,
    ctaLink: `https://webinars.segment.com/segment/cdp-live-closing-the-personalization-gap?utm_source=homepage-banner&utm_medium=referral&utm_campaign=all_ic_2024-08-28-wbnr-cdp-live_`,
  },
}

const HomeView: FC = () => {
  const theme = useTheme()

  return (
    <AppearanceProvider appearance="twilioLight">
      <Layout
        pageTitle="Twilio Segment - Ranked #1 Customer Data Platform four years in a row per IDC (2019-2022)"
        pageDescription="Twilio Segment is a customer data platform (CDP) that helps you collect, clean, and activate your customer data."
        pageOG={ogImageJpeg}
        themeColor={theme.palette.brand.darkAcai[700]}
      >
        <Navigation {...announcementsCopy} />
        <Hero appearance="twilioDark" />
        <Main>
          <DataArchitecture />
          <UseCase />
          <LogoCarouselWrapper>
            <LogoCarousel />
          </LogoCarouselWrapper>

          <Interactions />
          <ActivateWarehouseData />
          <Integrations />
          <TestimonialsSlider />
          <TheGrowthReport />
          <GettingStarted />
        </Main>
      </Layout>
      <Footer />
    </AppearanceProvider>
  )
}

const Main = styled.main`
  background: ${({ theme }) => theme.palette.neutrals.white[100]};
`

const LogoCarouselWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 40px;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding: 64px 0;
  }
`

export default HomeView
